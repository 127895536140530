import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, Header, Image, Button, Card, Transition } from 'semantic-ui-react';

const Training = observer(({ question, handleNext, preloadedImages }) => {
  const [audioPlaying, setAudioPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlayAudio = () => {
    if (question) {
      const audioPath = question.audioPath1;
      const audioUrl = getAudioUrl(audioPath);
      if (audioUrl) {
        const audio = new Audio(audioUrl);
        audio.play();
        setAudioPlaying(true);
        audio.onended = () => setAudioPlaying(false);
      }
    }
  };

  const getImageUrl = (imagePath) => {
    console.log(preloadedImages)
    if(preloadedImages[imagePath]) {
      console.log("Image path " + imagePath + " found in preload images")
    }
    else {
      console.log("Image path " + imagePath + " not found")
    }
    return preloadedImages[imagePath] ? `/aws/data/${imagePath}` : '';
  };

  const getAudioUrl = (audioPath) => {
    return `/aws/data/${audioPath}`;
  };

  return (
    <Container textAlign="center" style={{ padding: '10px', background: '#fff', maxHeight: '77vh', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', margin: 0 }}>
      <div style={{ margin: '0 auto', maxWidth: '600px' }}>
        <Header as="h1" style={{ marginBottom: '10px', fontSize: '2.5em', color: '#333' }}>
          {question.questionText}
        </Header>
        <Card centered style={{ width: '300px', height: '300px', margin: '0 auto', marginBottom: '30px', borderRadius: '15px', overflow: 'hidden', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
            <Image src={getImageUrl(question.correctImagePath1)} style={{ height: '100%', objectFit: 'cover' }} />
        </Card>
        <div style={{ margin: '20px 0' }}>
          <Button icon='volume up' onClick={handlePlayAudio} disabled={audioPlaying} style={{ marginLeft: '10px' }} />
        </div>
        <Button size="huge" onClick={handleNext} primary style={{ padding: '15px 30px', borderRadius: '15px', backgroundColor: '#4CAF50', color: '#fff' }}>
          Next
        </Button>
        <audio ref={audioRef} src={getAudioUrl(question.audioPath1)} autoPlay />
      </div>
    </Container>
  );
});

export default Training;