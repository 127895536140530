import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Message,
  Dimmer,
  Loader,
  Modal,
  Button,
  Icon,
  Pagination,
  Dropdown,
} from "semantic-ui-react";
import styled from "styled-components";
import "semantic-ui-css/semantic.min.css";

const StyledContainer = styled(Container)`
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledTable = styled(Table)`
  &.ui.table {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-collapse: separate;
    border-spacing: 0 10px;
    font-size: 14px;
    color: #333;
  }
  &.ui.table thead th {
    background-color: #f1f3f5;
    color: #449bc0;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
  }
  &.ui.table tbody tr {
    background-color: #ffffff;
    transition: background-color 0.3s ease;
  }
  &.ui.table tbody tr:hover {
    background-color: #f9fafb;
  }
  &.ui.table tbody td {
    border-top: 1px solid #eee;
  }
  &.ui.table tbody tr:first-child td {
    border-top: none;
  }
`;

const StyledDimmer = styled(Dimmer)`
  &.ui.dimmer {
    background-color: rgba(255, 255, 255, 0.85);
  }
`;

const StyledLoader = styled(Loader)`
  &.ui.loader {
    color: #016fa4;
  }
`;

const StyledMessage = styled(Message)`
  &.ui.message {
    background-color: #fff6f6;
    color: #9f3a38;
    border: 1px solid #e0b4b4;
  }
`;

const Heading = styled.h1`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #016da1;
`;

const ViewClassStudent = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(10);
  const [selectedSection, setSelectedSection] = useState("");
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `/user/v1/getClassstudents`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch student details");
        }

        const data = await response.json();
        if (!data) {
          throw new Error("No student details found");
        }

        const formattedStudents = data.map((student) => ({
          ...student,
          lastLoggedInTime: new Date(student.lastLoggedInTime).toLocaleString(),
        }));

        setStudents(formattedStudents);
        setFilteredStudents(formattedStudents);
        setSections([...new Set(formattedStudents.map(student => student.section))]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching student details:", error);
        setError("Failed to fetch student details. Please try again.");
        setLoading(false);
      }
    };

    fetchStudents();
  }, []);

  const handleRowClick = (student) => {
    setSelectedStudent(student);
  };

  const handleClose = () => {
    setSelectedStudent(null);
  };

  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = filteredStudents.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );

  const handlePageChange = (event, data) => {
    setCurrentPage(data.activePage);
  };

  const handleSectionChange = (event, data) => {
    const selectedSection = data.value;
    setSelectedSection(selectedSection);

    if (selectedSection === "") {
      setFilteredStudents(students);
    } else {
      const filtered = students.filter(
        (student) => student.section === selectedSection
      );
      setFilteredStudents(filtered);
    }

    setCurrentPage(1);
  };

  return (
    <StyledContainer fluid>
      <Heading>My Students</Heading>

      {loading && (
        <StyledDimmer active inverted>
          <StyledLoader size="large">Loading...</StyledLoader>
        </StyledDimmer>
      )}
      {error && (
        <StyledMessage negative>
          <Message.Header>Error:</Message.Header>
          <p>{error}</p>
        </StyledMessage>
      )}
      {!loading && !error && (
        <>
          <Dropdown
            placeholder="Select Section"
            fluid
            selection
            options={[
              { key: 'all', value: '', text: 'All Sections' },
              ...sections.map((section) => ({
                key: section,
                value: section,
                text: section,
              })),
            ]}
            value={selectedSection}
            onChange={handleSectionChange}
            style={{ marginBottom: "20px" }}
          />
          <StyledTable celled striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Roll No</Table.HeaderCell>
                <Table.HeaderCell>Admission No</Table.HeaderCell>
                <Table.HeaderCell>Student Name</Table.HeaderCell>
                <Table.HeaderCell>Class</Table.HeaderCell>
                <Table.HeaderCell>Section</Table.HeaderCell>
                <Table.HeaderCell>UserId</Table.HeaderCell>
                <Table.HeaderCell>Password</Table.HeaderCell>
                <Table.HeaderCell>Last Login</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {currentStudents.map((student) => (
                <Table.Row
                  key={student._id}
                  onDoubleClick={() => handleRowClick(student)}
                >
                  <Table.Cell>{student.rollno}</Table.Cell>
                  <Table.Cell>{student.admnNo}</Table.Cell>
                  <Table.Cell>{student.name}</Table.Cell>
                  <Table.Cell>{student.class}</Table.Cell>
                  <Table.Cell>{student.section}</Table.Cell>
                  <Table.Cell>{student.username}</Table.Cell>
                  <Table.Cell>{student.password}</Table.Cell>
                  <Table.Cell>{student.lastLoggedInTime}</Table.Cell>
                  <Table.Cell>
                    <Icon
                      name="eye"
                      link
                      onClick={() => handleRowClick(student)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </StyledTable>

          <Pagination
            activePage={currentPage}
            onPageChange={handlePageChange}
            totalPages={Math.ceil(filteredStudents.length / studentsPerPage)}
            boundaryRange={0}
            siblingRange={1}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            style={{ marginTop: "20px" }}
          />

          {selectedStudent && (
            <Modal
              open={!!selectedStudent}
              onClose={handleClose}
              size="large"
              style={{ width: "100%", maxWidth: "none", height: "100vh" }}
            >
              <Modal.Header style={{ textAlign: "center" }}>
                {selectedStudent.name}
              </Modal.Header>
              <Modal.Content>
                <Button
                  icon="close"
                  onClick={handleClose}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    zIndex: "1",
                    color: "black", // Change icon color to black
                  }}
                />
                <StyledTable celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Chapter Name</Table.HeaderCell>
                      <Table.HeaderCell>Completed Lessons</Table.HeaderCell>
                      <Table.HeaderCell>Score</Table.HeaderCell>
                      <Table.HeaderCell>Time Taken</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {selectedStudent.completedChapters.map((chapter) =>
                  (
                      <Table.Row key={chapter._id}>
                        <Table.Cell>{chapter.chapter_name}</Table.Cell>
                        <Table.Cell>
                          <ul>
                            {chapter.completedLessons.map((lesson) => (
                              <li key={lesson._id}>{lesson.lesson_name}</li>
                            ))}
                          </ul>
                        </Table.Cell>
                        <Table.Cell>
                          <ul>
                            {chapter.completedLessons.map((lesson) => (
                              <li key={lesson._id}>{lesson.score}</li>
                            ))}
                          </ul>
                        </Table.Cell>
                        <Table.Cell>
                          <ul>
                            {chapter.completedLessons.map((lesson) => (
                              <li key={lesson._id}>{lesson.completedTime}</li>
                            ))}
                          </ul>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </StyledTable>
              </Modal.Content>
            </Modal>
          )}
        </>
      )}
    </StyledContainer>
  );
};

export default ViewClassStudent;
