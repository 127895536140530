import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Header, Container, Icon, Button, Loader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import Training from "./Training";
import Assessment from "./Assessment";
import { questionSessionStore } from "../store/questionSessionStore";
import LoaderComponent from "./LoaderComponent"

const TrainingAndAssessmentContainer = observer(({ selectedLessonId, selectedChapterId, username, setModalOpen, selectedChapterName, selectedLessonName, onLessonComplete }) => {
  const { questions, selectedLesson, score, completedTime } = questionSessionStore;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [preloadedImages, setPreloadedImages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const filteredQuestions = questions.filter(q => ["L1", "P1", "P2"].includes(q.question_type));
  const assessmentQuestions = questions.filter(q => ["P1", "P2"].includes(q.question_type));

  useEffect(() => {
    if (questions.length > 0) {
      const intervalId = setInterval(() => {
        setTimeElapsed((prevTimeElapsed) => prevTimeElapsed + 1);
      }, 1000);
      setTimerInterval(intervalId);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [questions]);

  useEffect(() => {
    if (filteredQuestions.length > 0) {
      const imageUrls = filteredQuestions.flatMap(question => [
        question.correctImagePath1,
        question.wrongImagePath1,
        question.wrongImagePath2,
        question.wrongImagePath3,
      ]).filter(Boolean);

      preloadImages(imageUrls);
    }
  }, [filteredQuestions]);

  const preloadImages = (imageUrls) => {
    const promises = imageUrls.map((url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = `/aws/data/${url}`;
        img.onload = () => resolve({ url, status: 'ok' });
        img.onerror = () => reject({ url, status: 'error' });
      });
    });

    Promise.all(promises)
      .then((results) => {
        const loadedImages = {};
        results.forEach(result => {
          if (result.status === 'ok') {
            loadedImages[result.url] = true;
          }
        });
        setPreloadedImages(loadedImages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Failed to preload images:', error);
        setIsLoading(false);
      });
  };

  const handleNext = () => {
    if (currentIndex < filteredQuestions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      const formattedTime = `${Math.floor(timeElapsed / 60)
        .toString()
        .padStart(2, "0")}:${(timeElapsed % 60).toString().padStart(2, "0")}`;
      questionSessionStore.setCompletedTime(formattedTime);
      setShowConfetti(true);
      clearInterval(timerInterval);
      onLessonComplete();
      setTimeout(() => {
        navigate("/student");
      }, 4000);
    }
  };

  const handleNextLesson = () => {
    questionSessionStore.clear();
    setModalOpen(false);
  };

  const handleSkip = () => {
    handleNext();
  };

  if (!selectedLesson) {
    return <div>Please select a lesson to start.</div>;
  }

  const formattedTime = `${Math.floor(timeElapsed / 60)
    .toString()
    .padStart(2, "0")}:${(timeElapsed % 60).toString().padStart(2, "0")}`;

  return (
    <Container
      style={{
        background: "#fff",
        borderRadius: "10px",
        width: "100%",
        margin: 0,
        maxHeight: "100%",
        boxSizing: "border-box",
        padding: "10px",
        margin: "0px",
        position: 'relative'
      }}
    >
      {isLoading ? (
        <LoaderComponent/>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0px",
              maxHeight: "10%",
              width: "100%",
              position: 'relative'
            }}
          >
            <div>
              <Icon name="heart" color="red" />
              <span style={{ marginLeft: "5px", fontWeight: "bold", fontSize: "20px" }}>
                Score: {score} / {assessmentQuestions.length}
              </span>
            </div>
            <div>
              <Icon name="clock" />
              <span style={{ marginLeft: "5px", fontWeight: "bold", fontSize: "20px" }}>
                Time: {formattedTime}
              </span>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              objectFit: "cover",
              maxHeight: "90%",
              maxWidth: "100%",
              top: "30px",
              padding: "0px",
              margin: "10px",
            }}
          >
            {showConfetti ? (
              <div style={{ textAlign: "center", maxHeight: '77vh', position: 'relative' }}>
                <Header as="h2" style={{ color: "#21ba45", marginBottom: "20px" }}>
                  Congratulations! You have completed all parts.
                </Header>
                <p>Completed Time: {completedTime}</p>
                <p>
                  Score: {score} / {assessmentQuestions.length}
                </p>
                <Confetti />
                <Button primary onClick={handleNextLesson}>Next Lesson</Button>
              </div>
            ) : (
              filteredQuestions.length > 0 && (
                filteredQuestions[currentIndex].question_type === 'L1' ? (
                  <Training question={filteredQuestions[currentIndex]} handleNext={handleNext} preloadedImages={preloadedImages}/>
                ) : (
                  filteredQuestions[currentIndex].question_type === 'P1' || filteredQuestions[currentIndex].question_type === 'P2' ? (
                    <Assessment
                      question={filteredQuestions[currentIndex]}
                      handleNext={handleNext}
                      isLastQuestion={currentIndex === filteredQuestions.length - 1}
                      isLastPart={filteredQuestions.length}
                      selectedLessonId={selectedLessonId}
                      selectedChapterId={selectedChapterId}
                      username={username}
                      completedTime={formattedTime}
                      selectedChapterName={selectedChapterName}
                      selectedLessonName={selectedLessonName}
                      preloadedImages={preloadedImages}
                    />
                  ) : (
                    handleSkip()
                  )
                )
              )
            )}
          </div>
        </>
      )}
    </Container>
  );
});

export default TrainingAndAssessmentContainer;