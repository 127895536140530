import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Container, Header, Card, Image, Grid, Button, Icon } from "semantic-ui-react";
import correctSound from "../sounds/correct.mp3";
import wrongSound from "../sounds/wrong.mp3";
import { questionSessionStore } from "../store/questionSessionStore";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

const Assessment = observer(({ question, handleNext, isLastQuestion, isLastPart, selectedLessonId, selectedChapterId, username, completedTime, selectedChapterName, selectedLessonName, preloadedImages }) => {
  const [shuffledImages, setShuffledImages] = useState([]);
  const [feedback, setFeedback] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [attempted, setAttempted] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [scoreUpdated, setScoreUpdated] = useState(false);
  const [firstAttempt, setFirstAttempt] = useState(true);


  useEffect(() => {
    if (question && (question.question_type === 'P1' || question.question_type === 'P2')) {
      const { correctImagePath1, wrongImagePath1, wrongImagePath2, wrongImagePath3 } = question;
      const images = [correctImagePath1, wrongImagePath1, wrongImagePath2, wrongImagePath3].filter(Boolean);
      setShuffledImages(shuffleArray(images));
      setScoreUpdated(false); // Reset the flag for the new question
      setFirstAttempt(true); // Reset the first attempt flag for the new question
    }
  }, [question]);

  const handlePlayAudio = () => {
    if (question) {
      const audioPath = question.audioPath1;
      const audioUrl = getAudioUrl(audioPath);
      if (audioUrl) {
        const audio = new Audio(audioUrl);
        audio.play();
        setAudioPlaying(true);
        audio.onended = () => setAudioPlaying(false);
      }
    }
  };

  const handleImageClick = (imagePath) => {
    console.log("hello")
    console.log(preloadedImages)
    if (feedback) return; // Disable clicking if there is feedback

    const correct = imagePath === question.correctImagePath1;
    const sound = correct ? correctSound : wrongSound;
    const feedbackMessage = correct ? "Correct!" : "You are wrong! Try again";
    const feedbackCorrect = correct;
    new Audio(sound).play();
    setFeedback({ message: feedbackMessage, correct: feedbackCorrect });

    if (correct && !scoreUpdated && firstAttempt) {
      questionSessionStore.incrementScore();
      setScoreUpdated(true); // Mark the score as updated
    }

    setFirstAttempt(false); // After the first click, it is no longer the first attempt
    setAttempted(true);
  };

  const handleContinue = async () => {
    if (feedback.correct) {
      setFeedback(null);
      if (currentIndex < isLastPart) {
        setCurrentIndex(currentIndex + 1);
        setAttempted(false);
        handleNext();
      } 
      if (isLastQuestion) {
        await updateCompletedChapters(username, selectedChapterName, selectedChapterId, selectedLessonName, selectedLessonId, questionSessionStore.score, completedTime);
        console.log(updateCompletedChapters);
      }
      handleNext();
    } else {
      setShuffledImages(shuffleArray(shuffledImages));
      setFeedback(null);
      setAttempted(false);
      handlePlayAudio(); // Play audio when reshuffling for the wrong answer
    }
  };

  const getImageUrl = (imagePath) => {
    console.log("hello")
    console.log(preloadedImages)
    if(preloadedImages[imagePath]) {
      console.log("PreloadImage array still has " + imagePath)
    }
    if (!imagePath) return "";
    return `/aws/data/${imagePath}`;
  };

  const getAudioUrl = (audioPath) => {
    if (!audioPath) return "";
    return `/aws/data/${audioPath}`;
  };

  const updateCompletedChapters = async (username, chapter_name, chapter_id, lesson_name, lesson_id, score, completedTime) => {
    console.log(username, chapter_name, chapter_id, lesson_name, lesson_id, score, completedTime);
    try {
      const response = await fetch(`/user/${username}/completedChapters`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chapter_name, chapter_id, lesson_name, lesson_id, score, completedTime }),
      });

      if (!response.ok) {
        throw new Error('Failed to update completed chapters');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating completed chapters:', error);
    }
  };

  useEffect(() => {
    handlePlayAudio();
  }, [question]);

  if (!question) {
    return null;
  }

  return (
    <Container fluid style={{ padding: '0', margin: '0', maxHeight: '77vh', alignItems: 'center', justifyContent: 'center', background: '#fff', position: 'absolute' }}>
      <div style={{ width: '100%', maxHeight: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ maxWidth: '500px', maxHeight: '77vh', position: 'relative', display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative', maxHeight: '75vh', padding: '0px', margin: '0px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '25px', maxBlockSize: '100%', position: 'relative', maxHeight: '7vh' }}>
              <Header as="h1" textAlign="center" style={{ fontSize: '1.8em', color: '#333', margin: '0', position: 'relative' }}>
                {question.questionText}
              </Header>
              <Button icon='volume up' onClick={handlePlayAudio} disabled={audioPlaying} style={{ marginLeft: '10px' }} />
            </div>
            <Grid columns={2} centered stackable style={{ marginBottom: "10px", position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '93vh' }}>
              {shuffledImages.map((imagePath, idx) => (
                <Grid.Column key={idx} style={{ padding: "10px", position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '93vh' }}>
                  <Card
                    onClick={() => handleImageClick(imagePath)}
                    style={{
                      cursor: feedback ? "not-allowed" : "pointer", // Change cursor style if feedback is present
                      borderRadius: "10px",
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                      width: '260px',
                      height: '250px',
                      border: '2px solid #d1d1d1',
                      overflow: 'hidden'
                    }}
                  >
                    <Image
                      src={getImageUrl(imagePath)}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                        borderRadius: "10px",
                      }}
                    />
                  </Card>
                </Grid.Column>
              ))}
            </Grid>
          </div>
        </div>
      </div>
      {feedback && (
        <div
          style={{
            minWidth: '100%',
            padding: '20px',
            backgroundColor: feedback.correct ? '#d4edda' : '#f8d7da',
            color: feedback.correct ? '#155724' : '#721c24',
            textAlign: 'center',
            position: 'fixed',
            bottom: '-3px',
            left: '0',
            zIndex: '1000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Icon name={feedback.correct ? "check circle outline" : "times circle outline"} size="large" />
          <div style={{ fontSize: '1.2em', marginBottom: '10px' }}>{feedback.message}</div>
          <Button
            color={feedback.correct ? "green" : "red"}
            onClick={handleContinue}
            style={{ width: '150px' }}
          >
            {feedback.correct ? (isLastQuestion ? "Finish" : "Next") : "Retry"}
          </Button>
        </div>
      )}
    </Container>
  );
});

export default Assessment;