import React, { useState, useEffect } from "react";
import { Container, Table } from "semantic-ui-react";
import styled from "styled-components";
import "semantic-ui-css/semantic.min.css";

const StyledContainer = styled(Container)`
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
`;

const StyledTable = styled(Table)`
  &.ui.table {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-collapse: separate;
    border-spacing: 0 10px;
    font-size: 14px;
    color: #333;
  }
  &.ui.table thead th {
    background-color: #f1f3f5;
    color: #449bc0;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
  }
  &.ui.table tbody tr {
    background-color: #ffffff;
    transition: background-color 0.3s ease;
  }
  &.ui.table tbody tr:hover {
    background-color: #f9fafb;
  }
  &.ui.table tbody td {
    border-top: 1px solid #eee;
  }
  &.ui.table tbody tr:first-child td {
    border-top: none;
  }
`;

const Heading = styled.h1`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #016da1;
`;

const ViewTeachers = () => {
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = localStorage.getItem("token");
        const instituteKey = sessionStorage.getItem("institutekey");
        const response = await fetch(
          `/user/v1/getteachers?instituteKey=${instituteKey}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch teacher details");
        }

        const data = await response.json();
        if (!data || !Array.isArray(data)) {
          throw new Error("No teacher details found");
        }

        setTeachers(data);
      } catch (error) {
        console.error("Error fetching teacher details:", error);
      }
    };

    fetchTeachers();
  }, []);

  return (
    <StyledContainer fluid>
      <Heading>Teacher Dashboard</Heading>
      <StyledTable celled striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Sl No</Table.HeaderCell>
            <Table.HeaderCell>Teacher Name</Table.HeaderCell>
            <Table.HeaderCell>Class</Table.HeaderCell>
         
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Password</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {teachers.map((teacher, index) => (
            <Table.Row key={teacher.username}>
              <Table.Cell>{index + 1}</Table.Cell>
              <Table.Cell>{teacher.name}</Table.Cell>
              <Table.Cell>{teacher.class}</Table.Cell>
            
              <Table.Cell>{teacher.username}</Table.Cell>
              <Table.Cell>{teacher.password}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </StyledTable>
    </StyledContainer>
  );
};

export default ViewTeachers;
