// Scorecard.js
import React from 'react';

const Scorecard = () => {
  return (
    <div>
      <h3>Scorecard</h3>
      {/* Scorecard content goes here */}
    </div>
  );
};

export default Scorecard;
