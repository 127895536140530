import React, { useState } from 'react';
import { Container, Segment, Button, Form, Message, Grid } from 'semantic-ui-react';
import validator from 'validator';

const AddTeachers = () => {
    const [formData, setFormData] = useState({
        Teacher_name: '',
        Class: '',
        username: '',
        password: '',
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e, { name, value }) => {
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const errors = {};
        if (validator.isEmpty(formData.Teacher_name)) {
            errors.Teacher_name = 'Teacher name is required';
        }
        if (validator.isEmpty(formData.Class)) {
            errors.Class = 'Class is required';
        }
        if (validator.isEmpty(formData.username)) {
            errors.username = 'Username is required';
        }
        if (validator.isEmpty(formData.password)) {
            errors.password = 'Password is required';
        }
        return errors;
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);
        setSuccessMessage(null);

        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setLoading(false);
            return;
        }

        setFormErrors({});
        const token = localStorage.getItem('token');

        try {
            const response = await fetch('/user/v1/addteacher', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.message || 'Failed to add teacher');
            }

            setSuccessMessage('Teacher added successfully');
            setFormData({
                Teacher_name: '',
                Class: '',
                username: '',
                password: '',
            });
        } catch (error) {
            console.error('Error adding teacher:', error);
            setError(error.message || 'Failed to add teacher. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container fluid textAlign='center' style={{ backgroundColor: '#E6EFF4', color: '#1c1c1c', width: '100vw', maxWidth: '100%', padding: '2em' }}>
            <Segment style={{ backgroundColor: '#E7EEF4', color: '#5B9DBF', padding: '3em', borderRadius: '15px' }}>
                <Form onSubmit={handleSubmit} error={error !== null} success={successMessage !== null} loading={loading}>
                    <Grid columns={2} stackable>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Input
                                    label="Teacher Name"
                                    placeholder='Teacher Name'
                                    name='Teacher_name'
                                    value={formData.Teacher_name}
                                    onChange={handleChange}
                                    error={formErrors.Teacher_name ? { content: formErrors.Teacher_name } : null}
                                    required
                                />
                                <Form.Input
                                    label="Class"
                                    placeholder='Class'
                                    name='Class'
                                    value={formData.Class}
                                    onChange={handleChange}
                                    error={formErrors.Class ? { content: formErrors.Class } : null}
                                    required
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    label="Username"
                                    placeholder='Username'
                                    name='username'
                                    value={formData.username}
                                    onChange={handleChange}
                                    error={formErrors.username ? { content: formErrors.username } : null}
                                    required
                                />
                                <Form.Input
                                    label="Password"
                                    placeholder='Password'
                                    name='password'
                                    type='password'
                                    value={formData.password}
                                    onChange={handleChange}
                                    error={formErrors.password ? { content: formErrors.password } : null}
                                    required
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Message
                        success
                        header='Success'
                        content={successMessage}
                    />
                    <Message
                        error
                        header='Error'
                        content={error}
                    />
                    <Button primary type='submit' style={{ backgroundColor: '#E50914', color: '#fff', marginTop: '1em', borderRadius: '15px' }}>Add Teacher</Button>
                </Form>
            </Segment>
        </Container>
    );
};

export default AddTeachers;
